// Services & Helpers
import * as Sentry from "@sentry/vue";

// Composables
import { toast } from "@/composables/useCustomToast";
import { useUser } from "@/composables/useUser";

// Types
import { ToastErrorMessage } from "@/core/services/error/types";

type ErrorExtraFields = {
  [key: string]: string | number | boolean;
};

export function handleRuntimeErrors(
  error: unknown,
  { title, message, showToast = true }: Partial<ToastErrorMessage> = {},
  extraFields: ErrorExtraFields = {}
) {
  const { userStatus } = useUser();

  Sentry.withScope(scope => {
    scope.setExtra("message", message);
    scope.setExtra("teamId", userStatus.value?.activeTeam?.id);
    scope.setExtra("projectId", userStatus.value?.lastActiveProject?.id);
    scope.setExtra("role", userStatus.value?.activeTeam?.role);
    scope.setExtra("extra", extraFields);
    Sentry.captureException(error);
  });

  if (showToast)
    toast.error({ title: title || "Erro", message: message || (error as string) || "Ocorreu um erro inesperado" });
}
