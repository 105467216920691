// Core
import { ref, computed } from "vue";

// Libaries
import { pinia } from "@/main";
import { projectAuth } from "@/firebase/config";
import { onAuthStateChanged, getAuth, signOut } from "firebase/auth";

// Services & Helpers
import { storageSession } from "@/utils/storage";
import { apiService } from "@/core/services/apiService";
import { handleRuntimeErrors } from "@/helpers/error-handler";
import { errorHandlerService } from "@/core/services/error";

// Stores
import { useUserStore } from "@/store/user";

// Types
import { UserDetailsAdmin, UserStatusType } from "./../@types/user";

type UserPreferences = {
  viewedLatestNews: boolean;
  dailyFirstAccessModal: boolean;
  disableUpdatedProjectFeatureModal?: boolean;
  theme: "light" | "dark";
};

const user = ref(projectAuth.currentUser);
const userStatus = ref<UserStatusType>({} as UserStatusType);

export const isUserTeamMember = computed(() => {
  const role = userStatus.value?.activeTeam?.role;
  return role !== "admin";
});

// Auth changes
onAuthStateChanged(projectAuth, async _user => {
  if (projectAuth.currentUser == null) {
    storageSession.clear();

    try {
      await signOut(getAuth());
      userStatus.value = {} as UserStatusType;
    } catch (err) {
      handleRuntimeErrors(err);
    }
  }
  if (_user) {
    user.value = _user;
    const result = await apiService.get<UserStatusType>({ endpoint: `users/${user.value?.uid}` });
    if (result.error || !result.data) {
      errorHandlerService.handleError(result.error);
      return;
    }
    userStatus.value = result.data;
  } else {
    user.value = false;
    userStatus.value = {} as UserStatusType;
  }
});

const updateStatus = async () => {
  const userStore = useUserStore();

  if (!userStore?.userId) {
    userStatus.value = {} as UserStatusType;
    userStore.userInfos = {} as unknown as UserDetailsAdmin;
    return;
  }

  const result = await apiService.get<UserStatusType>({ endpoint: `users/${userStore?.userId}` });

  if (result.error || !result.data) {
    errorHandlerService.handleError(result.error);
    userStatus.value = {} as UserStatusType;
    userStore.userInfos = {} as unknown as UserDetailsAdmin;
    return;
  }

  userStatus.value = result.data;
  userStore.userInfos = userStatus.value;

  if (window.clarity) {
    window.clarity("identify", userStore.userInfos.email);
    window.clarity("set", "email", userStore.userInfos.email);
    window.clarity("set", "userId", userStore.userId);
  }
};

const updateUserPreferences = async (preferences: Partial<UserPreferences>) => {
  if (userStatus.value) {
    userStatus.value.preferences = { ...userStatus.value.preferences, ...preferences };
  }

  const { error } = await apiService.patch({
    endpoint: `users`,
    body: { preferences: { ...preferences } }
  });

  return error;
};

const viewLatestNotification = async () => {
  return updateUserPreferences({
    viewedLatestNews: true
  });
};

const disableUpdatedProjectFeatureModal = async () => {
  return updateUserPreferences({
    disableUpdatedProjectFeatureModal: true
  });
};

const toggleDailyFirstAccessModal = async (value: boolean) => {
  return updateUserPreferences({
    dailyFirstAccessModal: value
  });
};

const isUserFirstAccess = computed(() => {
  const userOnboardingStatus = userStatus.value?.onboarding;
  return !userOnboardingStatus.hasCompletedTour && !userOnboardingStatus.hasCompletedStep2;
});

export const useUser = () => {
  return {
    user,
    userStatus,
    isUserFirstAccess,
    updateStatus,
    updateUserPreferences,
    viewLatestNotification,
    toggleDailyFirstAccessModal,
    disableUpdatedProjectFeatureModal
  };
};
